import React from 'react';

const PoliticaPrivacidade: React.FC = () => {
  return (
    <div style={{ padding: '40px 20px', maxWidth: '900px', margin: '0 auto', backgroundColor: '#f9f9f9', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
      <h1 style={{ color: '#2E86C1', textAlign: 'center', marginBottom: '30px', fontWeight: 'bold' }}>Política de Privacidade</h1>
      
      <p style={{ color: '#555', fontSize: '16px', lineHeight: '1.7', marginBottom: '20px' }}>Última atualização: 27 de Setembro de 2024</p>

      <p style={{ color: '#555', fontSize: '16px', lineHeight: '1.7' }}>
        Esta Política de Privacidade descreve nossas políticas e procedimentos sobre a coleta, uso e divulgação de suas informações quando você usa o Serviço e informa sobre seus direitos de privacidade e como a lei protege você.
      </p>
      
      <p style={{ color: '#555', fontSize: '16px', lineHeight: '1.7' }}>
        Utilizamos seus dados pessoais para fornecer e melhorar o Serviço. Ao usar o Serviço, você concorda com a coleta e o uso de informações de acordo com esta Política de Privacidade.
      </p>

      <h2 style={{ color: '#2E86C1', marginTop: '40px', marginBottom: '20px', fontSize: '24px' }}>Interpretação e Definições</h2>
      
      <h3 style={{ color: '#34495E', marginBottom: '15px', fontSize: '20px' }}>Interpretação</h3>
      <p style={{ color: '#555', fontSize: '16px', lineHeight: '1.7' }}>
        As palavras cuja letra inicial está em maiúscula têm significados definidos nas seguintes condições. As seguintes definições terão o mesmo significado, independentemente de aparecerem no singular ou no plural.
      </p>

      <h3 style={{ color: '#34495E', marginBottom: '15px', fontSize: '20px' }}>Definições</h3>
      <p style={{ color: '#555', fontSize: '16px', lineHeight: '1.7' }}>Para os fins desta Política de Privacidade:</p>
      <ul style={{ color: '#555', fontSize: '16px', lineHeight: '1.7', paddingLeft: '20px' }}>
        <li><strong>Conta</strong> significa uma conta única criada para você acessar nosso Serviço ou partes do nosso Serviço.</li>
        <li><strong>Afiliada</strong> significa uma entidade que controla, é controlada por ou está sob controle comum de uma parte.</li>
        <li><strong>Companhia</strong> refere-se à Investai.</li>
        <li><strong>Cookies</strong> são pequenos arquivos colocados em seu dispositivo contendo detalhes de seu histórico de navegação.</li>
        <li><strong>País</strong> refere-se ao Brasil.</li>
        <li><strong>Dispositivo</strong> significa qualquer dispositivo que pode acessar o Serviço.</li>
        <li><strong>Dados Pessoais</strong> são quaisquer informações relacionadas a um indivíduo identificado ou identificável.</li>
        <li><strong>Serviço</strong> refere-se ao site.</li>
      </ul>

      <h2 style={{ color: '#2E86C1', marginTop: '40px', marginBottom: '20px', fontSize: '24px' }}>Coleta e Uso dos Seus Dados Pessoais</h2>
      <h3 style={{ color: '#34495E', marginBottom: '15px', fontSize: '20px' }}>Tipos de Dados Coletados</h3>
      
      <h4 style={{ color: '#34495E', fontSize: '18px', marginBottom: '10px' }}>Dados Pessoais</h4>
      <p style={{ color: '#555', fontSize: '16px', lineHeight: '1.7' }}>
        Ao usar nosso Serviço, podemos solicitar que você forneça informações pessoais identificáveis, como:
      </p>
      <ul style={{ color: '#555', fontSize: '16px', lineHeight: '1.7', paddingLeft: '20px' }}>
        <li>Endereço de e-mail</li>
        <li>Nome e sobrenome</li>
        <li>Dados de uso</li>
      </ul>

      <h4 style={{ color: '#34495E', fontSize: '18px', marginBottom: '10px' }}>Dados de Uso</h4>
      <p style={{ color: '#555', fontSize: '16px', lineHeight: '1.7' }}>
        Dados de uso são coletados automaticamente ao utilizar o serviço, como o endereço de IP, tipo de navegador, entre outras informações técnicas.
      </p>

      <h2 style={{ color: '#2E86C1', marginTop: '40px', marginBottom: '20px', fontSize: '24px' }}>Uso dos Seus Dados Pessoais</h2>
      <p style={{ color: '#555', fontSize: '16px', lineHeight: '1.7' }}>
        A empresa pode usar dados pessoais para as seguintes finalidades:
      </p>
      <ul style={{ color: '#555', fontSize: '16px', lineHeight: '1.7', paddingLeft: '20px' }}>
        <li><strong>Para fornecer e manter nosso serviço</strong>, incluindo monitorar o uso do Serviço.</li>
        <li><strong>Para gerenciar sua conta</strong> e permitir seu acesso a funcionalidades específicas.</li>
        <li><strong>Para execução de um contrato</strong>, como o desenvolvimento de produtos ou serviços adquiridos.</li>
        <li><strong>Para entrar em contato com você</strong> por e-mail ou outros canais de comunicação eletrônica.</li>
      </ul>

      <h2 style={{ color: '#2E86C1', marginTop: '40px', marginBottom: '20px', fontSize: '24px' }}>Segurança dos Seus Dados Pessoais</h2>
      <p style={{ color: '#555', fontSize: '16px', lineHeight: '1.7' }}>
        A segurança dos seus dados pessoais é importante para nós. Utilizamos medidas técnicas adequadas para proteger suas informações.
      </p>

      <h2 style={{ color: '#2E86C1', marginTop: '40px', marginBottom: '20px', fontSize: '24px' }}>Contato</h2>
      <p style={{ color: '#555', fontSize: '16px', lineHeight: '1.7' }}>
        Se você tiver dúvidas sobre esta Política de Privacidade, entre em contato conosco:
      </p>
      <ul style={{ color: '#555', fontSize: '16px', lineHeight: '1.7', paddingLeft: '20px' }}>
        <li>Email: snaxofc11@gmail.com</li>
      </ul>
    </div>
  );
};

export default PoliticaPrivacidade;
