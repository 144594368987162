import React from 'react';
import { Box, Typography } from '@mui/material';


const Acoes: React.FC = () => {
  return (
    <Box sx={{ padding: '40px 20px', backgroundColor: '#f9f9f9' }}>
      {/* Cabeçalho da Página */}
      {/* <Typography variant="h3" align="center" gutterBottom sx={{ color: '#333', fontWeight: 'bold', marginBottom: '30px' }}>
        Investimento em Ações
      </Typography> */}

      <Typography variant="h6" align="center" sx={{ color: '#555', marginBottom: '40px' }}>
        Descubra as melhores estratégias e dicas para investir no mercado de ações.
      </Typography>

      {/* Carrossel de Artigos sobre Ações */}
      {/* <Carousel categoryTitle="Como Começar a Investir em Ações?" articles={comecandoinvestiracoes} /> */}
      {/* <Carousel categoryTitle="Estrategias de investimentos em Ações" articles={estrategiadeinvestimentosacoes} /> */}
      

      {/* Seção de Informação sobre Ações */}
      <Box sx={{ marginTop: '50px', padding: '40px', backgroundColor: '#fff', borderRadius: '15px', boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)' }}>
        <Typography variant="h4" gutterBottom sx={{ color: '#333', fontWeight: 'bold' }}>
          O que é o Mercado de Ações?
        </Typography>
        <Typography variant="body1" sx={{ color: '#555', lineHeight: '1.8' }}>
          O mercado de ações é um ambiente de negociações onde são compradas e vendidas ações de empresas de capital aberto. Ao adquirir ações, você se torna sócio da empresa e participa dos lucros e do crescimento dela.
        </Typography>

        <Typography variant="h5" gutterBottom sx={{ marginTop: '20px', color: '#333', fontWeight: 'bold' }}>
          Vantagens de Investir em Ações
        </Typography>
        <Typography variant="body1" sx={{ color: '#555', lineHeight: '1.8' }}>
          Investir em ações oferece potencial de altos retornos, diversificação de investimentos e participação no crescimento das empresas. Além disso, você pode receber dividendos e benefícios fiscais dependendo das suas estratégias de investimento.
        </Typography>

        <Typography variant="h5" gutterBottom sx={{ marginTop: '20px', color: '#333', fontWeight: 'bold' }}>
          Como Começar a Investir em Ações?
        </Typography>
        <Typography variant="body1" sx={{ color: '#555', lineHeight: '1.8' }}>
          Para começar, você precisa de uma conta em uma corretora de valores e de uma estratégia de investimento que se alinhe aos seus objetivos financeiros. Estude o mercado, analise as empresas e defina o momento certo para investir.
        </Typography>
      </Box>
    </Box>
  );
};

export default Acoes;
