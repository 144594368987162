import React from 'react';
 // Importando o componente Carousel
import { Box, Button, Typography } from '@mui/material';


const FIIs: React.FC = () => {
  return (
    <Box sx={{ padding: '40px 20px', backgroundColor: '#f9f9f9' }}>
      {/* Cabeçalho da Página */}
      <Typography variant="h3" align="center" gutterBottom sx={{ color: '#333', fontWeight: 'bold', marginBottom: '30px' }}>
        Fundos Imobiliários (FIIs)
      </Typography>

      {/* Carrossel de Artigos sobre FIIs */}
      {/* <Carousel categoryTitle="Como começar a investir em FIIs?" articles={fiis} /> */}


      
      {/* <Carousel categoryTitle="Por que investir em FIIs?" articles={fiis2} /> */}
      {/* Seção de Informação sobre FIIs */}
      <Box sx={{ marginTop: '50px', padding: '40px', backgroundColor: '#fff', borderRadius: '15px', boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)' }}>
        <Typography variant="h4" gutterBottom sx={{ color: '#333', fontWeight: 'bold' }}>
          O que são Fundos Imobiliários (FIIs)?
        </Typography>
        <Typography variant="body1" sx={{ color: '#555', lineHeight: '1.8' }}>
          Fundos Imobiliários, também conhecidos como FIIs, são veículos de investimento que permitem que investidores comuns
          participem de grandes empreendimentos imobiliários, como shopping centers, hospitais, escritórios comerciais e
          até mesmo galpões logísticos. Ao investir em FIIs, você adquire cotas e se torna sócio de uma parte desses ativos
          imobiliários. Isso significa que você pode ganhar dinheiro tanto com a valorização das cotas quanto com a distribuição
          dos rendimentos periódicos (provenientes dos aluguéis dos imóveis ou vendas).
        </Typography>

        <Typography variant="h5" gutterBottom sx={{ marginTop: '20px', color: '#333', fontWeight: 'bold' }}>
          Por que investir em FIIs?
        </Typography>
        <Typography variant="body1" sx={{ color: '#555', lineHeight: '1.8' }}>
          Investir em FIIs oferece a oportunidade de diversificar seu portfólio com ativos imobiliários sem precisar comprar
          diretamente imóveis. Além disso, os FIIs oferecem benefícios como isenção de imposto de renda sobre os rendimentos
          distribuídos para pessoas físicas, alta liquidez (possibilidade de compra e venda das cotas na Bolsa de Valores), e
          acesso a grandes empreendimentos que seriam inacessíveis para o investidor individual.
        </Typography>

        <Typography variant="h5" gutterBottom sx={{ marginTop: '20px', color: '#333', fontWeight: 'bold' }}>
          Tipos de Fundos Imobiliários
        </Typography>
        <Typography variant="body1" sx={{ color: '#555', lineHeight: '1.8' }}>
          Existem diversos tipos de FIIs, cada um focado em uma estratégia de investimento diferente. Alguns exemplos incluem:
        </Typography>
        <ul style={{ color: '#555', lineHeight: '1.8' }}>
          <li><strong>FIIs de Renda:</strong> focados em gerar renda passiva através de aluguéis de imóveis.</li>
          <li><strong>FIIs de Desenvolvimento:</strong> focados em desenvolver novos imóveis e vendê-los para obter lucro.</li>
          <li><strong>FIIs de Papel:</strong> investem em títulos imobiliários, como Certificados de Recebíveis Imobiliários (CRIs).</li>
          <li><strong>FIIs Híbridos:</strong> combinam diferentes estratégias de investimento.</li>
        </ul>

        <Typography variant="h5" gutterBottom sx={{ marginTop: '20px', color: '#333', fontWeight: 'bold' }}>
          Como escolher um FII para investir?
        </Typography>
        <Typography variant="body1" sx={{ color: '#555', lineHeight: '1.8' }}>
          Antes de investir em FIIs, é importante analisar diversos fatores como o tipo de imóvel em que o fundo investe,
          a localização dos imóveis, a gestão do fundo, e o histórico de distribuição de rendimentos. Além disso, é essencial
          avaliar o valor da cota do fundo em relação ao valor patrimonial do imóvel.
        </Typography>

        <Typography variant="h5" gutterBottom sx={{ marginTop: '20px', color: '#333', fontWeight: 'bold' }}>
          Como começar a investir em FIIs?
        </Typography>
        <Typography variant="body1" sx={{ color: '#555', lineHeight: '1.8' }}>
          Investir em FIIs é fácil. Basta abrir uma conta em uma corretora que ofereça acesso à B3 (Bolsa de Valores do Brasil),
          transferir o valor desejado para sua conta, e escolher os fundos imobiliários que deseja adquirir. A compra e venda
          de cotas de FIIs funcionam de forma semelhante à compra e venda de ações, sendo realizadas diretamente na Bolsa de Valores.
        </Typography>

        <Button
          variant="contained"
          size="large"
          sx={{
            marginTop: '30px',
            backgroundColor: '#007bff',
            color: '#fff',
            '&:hover': {
              backgroundColor: '#0056b3',
            },
          }}
          href="/artigos-fiis"
        >
          Leia mais artigos sobre FIIs
        </Button>
      </Box>
    </Box>
  );
};

export default FIIs;
