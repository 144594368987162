import React, { useState } from 'react';
import axios from 'axios';
import '../styles/DividendCalculator.css';
import IOSSlider from './CustomizedSlider';
import { Card, CardContent, Typography, Grid, Box } from '@mui/material';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import TimelineIcon from '@mui/icons-material/Timeline';
import { createTheme, ThemeProvider } from '@mui/material/styles';

export const getDividends = async (symbol: string) => {
  try {
    const response = await axios.get(
      `https://investai.netlify.app/.netlify/functions/myFunction?symbol=${symbol}`
    );
    return response.data;
  } catch (error) {
    console.error('Erro ao buscar dados de dividendos:', error);
    throw error;
  }
};

const theme = createTheme({
  typography: {
    fontFamily: ['"Lato"', 'sans-serif'].join(','),
    h4: {
      fontSize: '1.75rem',
      fontWeight: 600,
    },
    h6: {
      fontSize: '1.25rem',
      fontWeight: 500,
    },
  },
});

const DividendCalculator: React.FC = () => {
  const [symbol, setSymbol] = useState<string>('');
  const [latestPrice, setLatestPrice] = useState<string>('');
  const [annualDY, setAnnualDY] = useState<string>('');
  const [avgDYFiveYears, setAvgDYFiveYears] = useState<string>('');
  const [totalDividendsLastYear, setTotalDividendsLastYear] = useState<string>('');
  const [totalDividendsLastFiveYears, setTotalDividendsLastFiveYears] = useState<string>('');
  const [quantity, setQuantity] = useState<number>(1); // Quantidade de ações
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const fetchDividends = async () => {
    if (!symbol) {
      setErrorMessage('Por favor, insira o nome de uma ação ou FII.');
      return;
    }

    let adjustedSymbol = symbol;
    if (!symbol.endsWith('.SA')) {
      adjustedSymbol = `${symbol}.SA`;
    }

    setLoading(true);
    setErrorMessage('');

    try {
      const data = await getDividends(adjustedSymbol);
      if (!data || !data.latestPrice) {
        setErrorMessage(`Nenhuma informação encontrada para o símbolo ${adjustedSymbol}.`);
        setLatestPrice('');
        setAnnualDY('');
        setAvgDYFiveYears('');
        setTotalDividendsLastYear('');
        setTotalDividendsLastFiveYears('');
      } else {
        setLatestPrice(data.latestPrice);
        setAnnualDY(data.annualDY);
        setAvgDYFiveYears(data.avgDYFiveYears);
        setTotalDividendsLastYear(data.totalDividendsLastYear);
        setTotalDividendsLastFiveYears(data.totalDividendsLastFiveYears);
      }
    } catch (error) {
      console.error(error);
      setErrorMessage('Não foi possível obter os dados. Verifique o símbolo e tente novamente.');
      setLatestPrice('');
      setAnnualDY('');
      setAvgDYFiveYears('');
      setTotalDividendsLastYear('');
      setTotalDividendsLastFiveYears('');
    } finally {
      setLoading(false);
    }
  };

  // Função para calcular os dividendos com base na quantidade de ações
  const calculateDividends = () => {
    if (totalDividendsLastYear && quantity) {
      return (parseFloat(totalDividendsLastYear) * quantity).toFixed(2);
    }
    return '0.00';
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="calculator-container">
        <h2>Calculadora de Dividendos</h2>

        <div className="input-container">
          <label htmlFor="symbolInput">Nome da Ação ou FII:</label>
          <input
            type="text"
            id="symbolInput"
            value={symbol}
            onChange={(e) => setSymbol(e.target.value.toUpperCase())}
            placeholder="Ex: PETR4 ou KNRI11"
            className="input-field"
          />
        </div>

        <div className="slider-container">
          <label htmlFor="slider">Selecione a quantidade de AÇÕES OU FIIS:</label>
          <IOSSlider
            aria-label="ios slider"
            value={quantity}
            onChange={(e: Event, value: number | number[]) => setQuantity(value as number)} // Ajuste de tipos
          />
        </div>
        <button onClick={fetchDividends} className="submit-button" disabled={loading}>
          {loading ? 'Carregando...' : 'Calcular Dividendos'}
        </button>

        {errorMessage && (
          <p className="error-message" style={{ backgroundColor: 'red', color: 'white', padding: '10px', borderRadius: '5px' }}>
            {errorMessage}
          </p>
        )}

        {latestPrice && annualDY && avgDYFiveYears && totalDividendsLastYear && totalDividendsLastFiveYears && (
          <Box mt={4}>
            <Typography variant="h5" align="center" gutterBottom>
              Resultados para {symbol}
            </Typography>
            <Grid container spacing={4} justifyContent="center">
              {/* Preço Atual da Ação */}
              <Grid item xs={12}>
                <Card sx={{ borderRadius: 2, boxShadow: 3 }}>
                  <CardContent>
                    <Box display="flex" alignItems="center">
                      <AttachMoneyIcon color="primary" fontSize="large" />
                      <Typography variant="h6" sx={{ ml: 2 }}>
                        Preço Atual da Ação
                      </Typography>
                    </Box>
                    <Typography variant="h4" color="secondary" sx={{ mt: 2 }}>
                      R$ {latestPrice}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>

              {/* DY Anualizado */}
              <Grid item xs={12}>
                <Card sx={{ borderRadius: 2, boxShadow: 3 }}>
                  <CardContent>
                    <Box display="flex" alignItems="center">
                      <TrendingUpIcon color="primary" fontSize="large" />
                      <Typography variant="h6" sx={{ ml: 2 }}>
                        DY Anualizado
                      </Typography>
                    </Box>
                    <Typography variant="h4" color="secondary" sx={{ mt: 2 }}>
                      {annualDY}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>

              {/* DY Médio de 5 Anos */}
              <Grid item xs={12}>
                <Card sx={{ borderRadius: 2, boxShadow: 3 }}>
                  <CardContent>
                    <Box display="flex" alignItems="center">
                      <TimelineIcon color="primary" fontSize="large" />
                      <Typography variant="h6" sx={{ ml: 2 }}>
                        DY Médio de 5 Anos
                      </Typography>
                    </Box>
                    <Typography variant="h4" color="secondary" sx={{ mt: 2 }}>
                      {avgDYFiveYears}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>

              {/* Dividendos do Último Ano */}
              <Grid item xs={12}>
                <Card sx={{ borderRadius: 2, boxShadow: 3 }}>
                  <CardContent>
                    <Box display="flex" alignItems="center">
                      <AttachMoneyIcon color="primary" fontSize="large" />
                      <Typography variant="h6" sx={{ ml: 2 }}>
                        Total Dividendos Último Ano
                      </Typography>
                    </Box>
                    <Typography variant="h4" color="secondary" sx={{ mt: 2 }}>
                      R$ {totalDividendsLastYear}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>

              {/* Provento Calculado */}
              <Grid item xs={12}>
                <Card sx={{ borderRadius: 2, boxShadow: 3 }}>
                  <CardContent>
                    <Box display="flex" alignItems="center">
                      <AttachMoneyIcon color="primary" fontSize="large" />
                      <Typography variant="h6" sx={{ ml: 2 }}>
                        Com {quantity} ações/FIIs, você receberia:
                      </Typography>
                    </Box>
                    <Typography variant="h4" color="secondary" sx={{ mt: 2 }}>
                      R$ {calculateDividends()}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>
        )}
      </div>
    </ThemeProvider>
  );
};

export default DividendCalculator;
