import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Header from './components/Header'; // Importando o componente Navbar
import AppRoutes from './routes/routes'; // Importando o arquivo de rotas
import Footer from './components/Footer'; // Importando o componente Footer
import CookieConsent from './components/CookieConsent';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline'; // Garante a aplicação de estilos globais mínimos

// Criar o tema com a fonte personalizada "Poppins"
const theme = createTheme({
  typography: {
    fontFamily: ['"Poppins"', 'sans-serif'].join(','), // Fonte personalizada
    h4: {
      fontSize: '1.75rem',
      fontWeight: 600,
    },
    h6: {
      fontSize: '1.25rem',
      fontWeight: 500,
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline /> {/* Reseta o CSS padrão para garantir um estilo consistente */}
      <Router>
        <Header />
        <div className="App">
          <AppRoutes /> {/* Usando o arquivo de rotas */}
        </div>
        <CookieConsent />
        <Footer />
      </Router>
    </ThemeProvider>
  );
}

export default App;
