import React from 'react';
import { Box, Typography, Link } from '@mui/material';

const PoliticaCookies: React.FC = () => {
  return (
    <Box sx={{ padding: '40px 20px', backgroundColor: '#f9f9f9', color: '#555' }}>
      <Typography variant="h2" align="center" gutterBottom sx={{ fontWeight: 'bold', color: '#1976d2' }}>
        Política de Cookies
      </Typography>

      <Box sx={{ maxWidth: '900px', margin: '0 auto' }}>
        <Typography variant="body1" paragraph>
          Esta Política de Cookies explica como o <strong>InvestAI</strong> utiliza cookies e tecnologias similares para reconhecer você quando visita nosso site. Ela explica o que são essas tecnologias e por que as usamos, bem como seus direitos de controlar o uso delas.
        </Typography>

        <Typography variant="h4" sx={{ fontWeight: 'bold', marginTop: '20px', color: '#1976d2' }}>
          O que são Cookies?
        </Typography>
        <Typography variant="body1" paragraph>
          Cookies são pequenos arquivos de texto que são armazenados no seu dispositivo (computador, tablet ou celular) enquanto você navega em um site. Eles permitem que o site reconheça o seu dispositivo e armazene algumas informações sobre suas preferências ou ações anteriores.
        </Typography>

        <Typography variant="h4" sx={{ fontWeight: 'bold', marginTop: '20px', color: '#1976d2' }}>
          Como usamos os Cookies?
        </Typography>
        <Typography variant="body1" paragraph>
          Utilizamos cookies por vários motivos, incluindo:
        </Typography>
        <ul>
          <li>
            <Typography variant="body1">Para melhorar a experiência de navegação, memorizando suas preferências e visitas anteriores.</Typography>
          </li>
          <li>
            <Typography variant="body1">Para analisar o tráfego do site e entender como os usuários interagem com nosso conteúdo.</Typography>
          </li>
          <li>
            <Typography variant="body1">Para exibir anúncios personalizados e relevantes para você.</Typography>
          </li>
          <li>
            <Typography variant="body1">Para facilitar a integração com redes sociais e permitir que você compartilhe conteúdo diretamente em plataformas como Facebook, Twitter, etc.</Typography>
          </li>
        </ul>

        <Typography variant="h4" sx={{ fontWeight: 'bold', marginTop: '20px', color: '#1976d2' }}>
          Tipos de Cookies Utilizados
        </Typography>
        <Typography variant="body1" paragraph>
          Nosso site utiliza os seguintes tipos de cookies:
        </Typography>
        <ul>
          <li>
            <Typography variant="body1">
              <strong>Cookies Essenciais:</strong> São necessários para o funcionamento do site e permitem a navegação e o uso de recursos, como acesso a áreas seguras.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              <strong>Cookies de Desempenho:</strong> Coletam informações sobre como os visitantes usam o site, como quais páginas são mais visitadas, para ajudar a melhorar a navegação.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              <strong>Cookies de Funcionalidade:</strong> Permitem que o site lembre suas escolhas (como idioma ou região) para proporcionar uma experiência mais personalizada.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              <strong>Cookies de Publicidade:</strong> São usados para exibir anúncios relevantes para você, com base nos seus interesses.
            </Typography>
          </li>
        </ul>

        <Typography variant="h4" sx={{ fontWeight: 'bold', marginTop: '20px', color: '#1976d2' }}>
          Como Gerenciar Cookies?
        </Typography>
        <Typography variant="body1" paragraph>
          Você pode gerenciar suas preferências de cookies a qualquer momento através das configurações do seu navegador. Abaixo estão links para páginas de ajuda de navegadores populares:
        </Typography>
        <ul>
          <li>
            <Link href="https://support.google.com/chrome/answer/95647?hl=pt" target="_blank" rel="noopener noreferrer">
              Gerenciar cookies no Google Chrome
            </Link>
          </li>
          <li>
            <Link href="https://support.mozilla.org/pt-BR/kb/ativar-desativar-cookies" target="_blank" rel="noopener noreferrer">
              Gerenciar cookies no Firefox
            </Link>
          </li>
          <li>
            <Link href="https://support.apple.com/pt-br/guide/safari/sfri11471/mac" target="_blank" rel="noopener noreferrer">
              Gerenciar cookies no Safari
            </Link>
          </li>
          <li>
            <Link href="https://support.microsoft.com/pt-br/help/4027947/windows-delete-cookies" target="_blank" rel="noopener noreferrer">
              Gerenciar cookies no Edge
            </Link>
          </li>
        </ul>

        <Typography variant="h4" sx={{ fontWeight: 'bold', marginTop: '20px', color: '#1976d2' }}>
          Cookies de Terceiros
        </Typography>
        <Typography variant="body1" paragraph>
          Em alguns casos, usamos cookies fornecidos por terceiros confiáveis, como o Google Analytics, para entender como você utiliza nosso site e melhorar sua experiência.
        </Typography>

        <Typography variant="h4" sx={{ fontWeight: 'bold', marginTop: '20px', color: '#1976d2' }}>
          Atualizações à Política de Cookies
        </Typography>
        <Typography variant="body1" paragraph>
          Podemos atualizar esta Política de Cookies periodicamente para refletir mudanças em nossas práticas de uso de cookies. Verifique esta página regularmente para se manter informado sobre nossas práticas de privacidade.
        </Typography>

        <Typography variant="h4" sx={{ fontWeight: 'bold', marginTop: '20px', color: '#1976d2' }}>
          Contato
        </Typography>
        <Typography variant="body1" paragraph>
          Se você tiver alguma dúvida sobre nossa Política de Cookies, entre em contato conosco através do nosso e-mail: <Link href="mailto:contato@investai.com.br">contato@investai.com.br</Link>.
        </Typography>
      </Box>
    </Box>
  );
};

export default PoliticaCookies;
