import React from 'react';
import Carousel from '../components/Carousel'; // Importando o componente Carousel
import { Box, Typography } from '@mui/material';
import {financaspessoais} from '../data/artigos/educacao';



 

const EducacaoFinanceira: React.FC = () => {
  return (
    <Box sx={{ padding: '40px 20px', backgroundColor: '#f9f9f9' }}>
      {/* Cabeçalho da Página */}
      <Typography variant="h3" align="center" gutterBottom sx={{ color: '#333', fontWeight: 'bold', marginBottom: '30px' }}>
        Educação Financeira
      </Typography>

      <Typography variant="h6" align="center" sx={{ color: '#555', marginBottom: '40px' }}>
        Explore uma variedade de artigos educacionais sobre finanças pessoais, investimentos, planejamento financeiro e muito mais. Tudo para ajudar você a tomar decisões informadas sobre o seu dinheiro.
      </Typography>

      {/* Carrossel de Finanças Pessoais */}
      <Carousel categoryTitle="Finanças Pessoais" articles={financaspessoais} />

      {/* Carrossel de Noções Básicas sobre Investimentos */}
      {/* <Carousel categoryTitle="Noções Básicas de Investimentos" articles={nocoesbasicasdeinvestimentos} /> */}
      
      {/* Seção Informativa sobre Educação Financeira */}
      <Box sx={{ marginTop: '50px', padding: '40px', backgroundColor: '#fff', borderRadius: '15px', boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)' }}>
        <Typography variant="h4" gutterBottom sx={{ color: '#333', fontWeight: 'bold' }}>
          A Importância da Educação Financeira
        </Typography>
        <Typography variant="body1" sx={{ color: '#555', lineHeight: '1.8' }}>
          A educação financeira é fundamental para garantir que você saiba como gerenciar seu dinheiro, poupar, investir e planejar para o futuro. Ao adquirir conhecimento financeiro, você estará mais preparado para tomar decisões que impactam diretamente a sua qualidade de vida e a sua segurança financeira.
        </Typography>

        <Typography variant="h5" gutterBottom sx={{ marginTop: '20px', color: '#333', fontWeight: 'bold' }}>
          Finanças Pessoais: O Primeiro Passo
        </Typography>
        <Typography variant="body1" sx={{ color: '#555', lineHeight: '1.8' }}>
          O primeiro passo para a independência financeira é entender como administrar suas finanças pessoais. Isso inclui controlar seus gastos, criar um orçamento e definir metas financeiras claras. A partir dessas bases, você poderá avançar para investimentos e outras estratégias financeiras.
        </Typography>

        <Typography variant="h5" gutterBottom sx={{ marginTop: '20px', color: '#333', fontWeight: 'bold' }}>
          Noções Básicas de Investimentos
        </Typography>
        <Typography variant="body1" sx={{ color: '#555', lineHeight: '1.8' }}>
          Investir é uma das melhores formas de aumentar seu patrimônio ao longo do tempo. No entanto, é crucial entender os diferentes tipos de investimentos disponíveis, como renda fixa e renda variável, para tomar decisões que estejam de acordo com o seu perfil e objetivos financeiros.
        </Typography>

        <Typography variant="h5" gutterBottom sx={{ marginTop: '20px', color: '#333', fontWeight: 'bold' }}>
          O Futuro das Suas Finanças
        </Typography>
        <Typography variant="body1" sx={{ color: '#555', lineHeight: '1.8' }}>
          Aprender sobre educação financeira permitirá que você se planeje para o futuro, seja para a aposentadoria, a compra de uma casa ou a realização de grandes sonhos. Quanto mais cedo você começar a se educar financeiramente, mais seguro será o seu futuro.
        </Typography>
      </Box>
    </Box>
  );
};

export default EducacaoFinanceira;
