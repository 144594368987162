import React from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography, CardMedia, Divider, List, ListItem, ListItemText, Link as MuiLink, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { comecandoinvestiracoes } from '../data/artigos/acoes';
import { estrategiadeinvestimentosacoes } from '../data/artigos/acoes';
import { financaspessoais } from '../data/artigos/educacao';
import { nocoesbasicasdeinvestimentos } from '../data/artigos/educacao';
import { estudodecasos } from '../data/artigos/casos';
import { fiis } from '../data/artigos/fiis';
import { fiis2 } from '../data/artigos/fiis';
import { guiasdeinvestimentos } from '../data/artigos/investimentos';

interface Subtopic {
  subtitle: string;
  content: string;
}

interface Section {
  subtitle: string;
  content: string;
  image?: string; // Propriedade opcional
  subtopics?: Subtopic[]; // Propriedade opcional, já que pode não haver subtopics
}

interface Article {
  id: string;
  title: string;
  summary: string;
  image: string;
  coverImage: string;
  images: string[];
  date: string;
  comments: number;
  type: string;
  introduction: string;
  sections: Section[]; // A propriedade sections é uma lista de Section
  conclusion?: string; // Propriedade opcional
  faq?: { question: string; answer: string }[]; // FAQ opcional
  tips?: string[]; // Dicas rápidas opcionais
  internalLinks?: { link: string; title: string }[]; // Links internos opcionais
  externalLinks?: { link: string; title: string }[]; // Links externos opcionais
  afiliado?: { title: string; content: string; image: string; link: string }; // Informações do afiliado
  slug: string;
  metaDescription: string;
}

const ArticlePrinter: React.FC = () => {
  const { categoria, id } = useParams<{ categoria: string; id: string }>(); // Obtém a categoria e o ID da URL
  let categoriaArtigos: Article[] = comecandoinvestiracoes;
  // Exemplo de verificação da categoria
  if (categoria === 'financas-pessoais') {
    categoriaArtigos = financaspessoais;
  } else if (categoria === 'nocoes-basicas-de-investimentos') {
    categoriaArtigos = nocoesbasicasdeinvestimentos;
  } else if (categoria === 'estudo-de-casos') {
    categoriaArtigos = estudodecasos;
  } else if (categoria === 'estrategia-de-investimentos') {
    categoriaArtigos = guiasdeinvestimentos;
  } else if (categoria === 'estrategia-de-investimentos-acoes') {
    categoriaArtigos = estrategiadeinvestimentosacoes;
  } else if (categoria === 'comecando-fiis') {
    categoriaArtigos = fiis;
  } else if (categoria === 'porque-investir-fiis') {
    categoriaArtigos = fiis2;
  } else if (categoria === 'estrategia-de-investimentos') {
    categoriaArtigos = fiis2;
  }

  const artigo = categoriaArtigos.find((artigo) => artigo.id === id); // Encontra o artigo com base no ID
  if (!artigo) {
    return <Typography variant="h4" color="error" align="center" mt={4}>Artigo não encontrado</Typography>;
  }

  return (
    <Box sx={{ padding: '40px', maxWidth: '900px', margin: '0 auto', backgroundColor: '#fff', borderRadius: '15px', boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)' }}>
      {/* Título do artigo */}
      <Typography variant="h3" gutterBottom sx={{ fontWeight: 'bold', color: '#1976d2', marginBottom: '30px', textAlign: 'center' }}>
        {artigo.title}
      </Typography>

      {/* Imagem de Capa */}
      <CardMedia
        component="img"
        height="450"
        image={artigo.coverImage}
        alt={`Imagem relacionada ao artigo: ${artigo.title}`}
        sx={{ borderRadius: '15px', objectFit: 'cover', marginBottom: '30px' }}
      />

      {/* Introdução */}
      <Typography variant="body1" paragraph sx={{ fontSize: '1.25rem', lineHeight: '1.8', color: '#444', marginBottom: '30px' }}>
        {artigo.introduction}
      </Typography>

      <Divider sx={{ margin: '30px 0' }} />

      {/* Seções do artigo */}
      {artigo.sections.map((section, index) => (
        <Box key={index} sx={{ marginBottom: '40px' }}>
          <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#333', marginBottom: '20px' }}>
            {section.subtitle}
          </Typography>
          <Typography variant="body1" sx={{ lineHeight: '1.8', color: '#555', marginBottom: '20px' }}>
            {section.content}
          </Typography>

          {/* Exibindo imagem associada à seção (se disponível) */}
          {section.image && (
            <CardMedia
              component="img"
              height="300"
              image={section.image}
              alt={`Imagem ${index + 1}`}
              sx={{ borderRadius: '15px', objectFit: 'cover', marginBottom: '30px' }}
            />
          )}

          {/* Subtópicos dentro da seção (se houver) */}
          {section.subtopics && section.subtopics.map((subtopic, subIndex) => (
            <Box key={subIndex} sx={{ marginLeft: '20px', marginBottom: '20px' }}>
              <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#444', marginBottom: '10px' }}>
                {subtopic.subtitle}
              </Typography>
              <Typography variant="body2" sx={{ color: '#666' }}>
                {subtopic.content}
              </Typography>
            </Box>
          ))}
        </Box>
      ))}

      <Divider sx={{ margin: '30px 0' }} />

      {/* Conclusão */}
      {artigo.conclusion && (
        <>
          <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#333', marginBottom: '20px' }}>
            Conclusão
          </Typography>
          <Typography variant="body1" paragraph sx={{ lineHeight: '1.8', color: '#555' }}>
            {artigo.conclusion}
          </Typography>
          <Divider sx={{ margin: '30px 0' }} />
        </>
      )}

      {/* FAQ Section */}
      {artigo.faq && (
        <>
          <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#333', marginBottom: '20px' }}>
            Perguntas Frequentes (FAQ)
          </Typography>
          {artigo.faq.map((item, index) => (
            <Accordion key={index} sx={{ marginBottom: '10px' }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`faq-content-${index}`} id={`faq-header-${index}`}>
                <Typography variant="body1" sx={{ fontWeight: 'bold', color: '#1976d2' }}>
                  {item.question}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body2" sx={{ color: '#555' }}>
                  {item.answer}
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </>
      )}

      <Divider sx={{ margin: '30px 0' }} />

      {/* Dicas rápidas */}
      {artigo.tips && (
        <>
          <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#333', marginBottom: '20px' }}>
            Dicas Importantes
          </Typography>
          <List>
            {artigo.tips.map((tip, index) => (
              <ListItem key={index}>
                <ListItemText primary={tip} sx={{ fontSize: '1.1rem', color: '#555' }} />
              </ListItem>
            ))}
          </List>
        </>
      )}

      <Divider sx={{ margin: '30px 0' }} />

      {/* Sugestões de Links Internos */}
      {artigo.internalLinks && (
        <>
          <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#333', marginBottom: '20px' }}>
            Sugestão de Links Internos
          </Typography>
          <List>
            {artigo.internalLinks.map((link, index) => (
              <ListItem key={index}>
                <MuiLink href={link.link} underline="hover">
                  {link.title}
                </MuiLink>
              </ListItem>
            ))}
          </List>
        </>
      )}

      <Divider sx={{ margin: '30px 0' }} />

      {/* Sugestões de Links Externos */}
      {artigo.externalLinks && (
        <>
          <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#333', marginBottom: '20px' }}>
            Sugestão de Links Externos
          </Typography>
          <List>
            {artigo.externalLinks.map((link, index) => (
              <ListItem key={index}>
                <MuiLink href={link.link} underline="hover" target="_blank">
                  {link.title}
                </MuiLink>
              </ListItem>
            ))}
          </List>
        </>
      )}

      <Divider sx={{ margin: '30px 0' }} />

      {/* Afiliado (se disponível) */}
      {artigo.afiliado && (
        <MuiLink href={artigo.afiliado.link} target="_blank" underline="none" sx={{ textDecoration: 'none' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              padding: '20px',
              backgroundColor: '#f9f9f9',
              borderRadius: '15px',
              boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
              cursor: 'pointer',
              transition: 'transform 0.3s ease',
              '&:hover': { transform: 'scale(1.05)' },
            }}
          >
            <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#1976d2', marginBottom: '20px' }}>
              {artigo.afiliado.title}
            </Typography>
            <Typography variant="body1" sx={{ color: '#555', marginBottom: '20px', textAlign: 'center' }}>
              {artigo.afiliado.content}
            </Typography>
            <CardMedia
              component="img"
              height="200"
              image={artigo.afiliado.image}
              alt={`Afiliado - ${artigo.afiliado.title}`}
              sx={{ borderRadius: '15px', objectFit: 'cover' }}
            />
          </Box>
        </MuiLink>
      )}

      {/* Meta Descrição e Slug */}
      <Typography variant="body2" sx={{ fontStyle: 'italic', color: '#999', textAlign: 'right', marginTop: '30px' }}>
        Slug: {artigo.slug}
      </Typography>
      <Typography variant="body2" sx={{ fontStyle: 'italic', color: '#999', textAlign: 'right' }}>
        Meta Descrição: {artigo.metaDescription}
      </Typography>
    </Box>
  );
};

export default ArticlePrinter;
