import React, { useState } from 'react';
import { Box, Typography, TextField, Button, Grid, Card, CardContent } from '@mui/material';

const Contato: React.FC = () => {
  const [nome, setNome] = useState('');
  const [email, setEmail] = useState('');
  const [mensagem, setMensagem] = useState('');
  const [enviado, setEnviado] = useState(false);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Lógica de envio do formulário
    console.log('Formulário enviado:', { nome, email, mensagem });
    setEnviado(true);
  };

  return (
    <Box sx={{ padding: '40px 20px', backgroundColor: '#f9f9f9' }}>
      <Typography variant="h2" align="center" gutterBottom sx={{ fontWeight: 'bold', color: '#1976d2' }}>
        Fale Conosco
      </Typography>

      <Typography variant="body1" align="center" sx={{ maxWidth: '800px', margin: '0 auto', color: '#555' }}>
        No <strong>InvestAI</strong>, estamos sempre prontos para ouvir suas dúvidas, sugestões ou preocupações. Sinta-se à vontade para entrar em contato conosco por meio do formulário abaixo ou pelos outros meios de contato listados.
      </Typography>

      <Grid container spacing={4} sx={{ marginTop: '40px' }} justifyContent="center">
        <Grid item xs={12} md={6}>
          <Card sx={{ boxShadow: 3, borderRadius: 2 }}>
            <CardContent>
              <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#1976d2' }}>
                Formulário de Contato
              </Typography>
              <form onSubmit={handleSubmit}>
                <TextField
                  label="Nome"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  required
                  value={nome}
                  onChange={(e) => setNome(e.target.value)}
                />
                <TextField
                  label="Email"
                  type="email"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <TextField
                  label="Mensagem"
                  multiline
                  rows={4}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  required
                  value={mensagem}
                  onChange={(e) => setMensagem(e.target.value)}
                />
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  sx={{ marginTop: '20px' }}
                >
                  Enviar Mensagem
                </Button>
              </form>
              {enviado && (
                <Typography variant="body1" color="success.main" sx={{ marginTop: '20px', textAlign: 'center' }}>
                  Obrigado por entrar em contato! Responderemos em breve.
                </Typography>
              )}
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <Card sx={{ boxShadow: 3, borderRadius: 2 }}>
            <CardContent>
              <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#1976d2' }}>
                Outras Formas de Contato
              </Typography>
              <Typography variant="body2" color="textSecondary" sx={{ marginTop: 2 }}>
                <strong>Email:</strong> contato@investai.com.br
              </Typography>
              <Typography variant="body2" color="textSecondary" sx={{ marginTop: 1 }}>
                <strong>Telefone:</strong> +55 (11) 1234-5678
              </Typography>
              <Typography variant="body2" color="textSecondary" sx={{ marginTop: 1 }}>
                <strong>Endereço:</strong> Rua dos Investidores, 123, São Paulo - SP, Brasil
              </Typography>
              <Typography variant="body2" color="textSecondary" sx={{ marginTop: 1 }}>
                <strong>Horário de Atendimento:</strong> Segunda a Sexta, das 9h às 18h
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Typography variant="h4" align="center" sx={{ marginTop: '60px', color: '#1976d2' }}>
        Compromisso com a Privacidade
      </Typography>
      <Typography variant="body1" align="center" sx={{ maxWidth: '800px', margin: '20px auto', color: '#555' }}>
        O <strong>InvestAI</strong> valoriza a privacidade de seus usuários. Garantimos que todas as informações fornecidas através deste formulário serão tratadas com total confidencialidade e não serão compartilhadas com terceiros sem o seu consentimento.
      </Typography>
    </Box>
  );
};

export default Contato;
