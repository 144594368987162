import React from 'react';

const TermosServicos: React.FC = () => {
  return (
    <div style={{ padding: '40px 20px', maxWidth: '900px', margin: '0 auto', backgroundColor: '#f9f9f9', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
      <h1 style={{ color: '#2E86C1', textAlign: 'center', marginBottom: '30px', fontWeight: 'bold' }}>Termos e Condições</h1>

      <p style={{ color: '#555', fontSize: '16px', lineHeight: '1.7' }}>Última atualização: 27 de Setembro de 2024</p>
      
      <p style={{ color: '#555', fontSize: '16px', lineHeight: '1.7' }}>
        Por favor, leia estes termos e condições cuidadosamente antes de usar nosso site.
      </p>

      <h2 style={{ color: '#2E86C1', marginTop: '40px', marginBottom: '20px', fontSize: '24px' }}>Interpretação e Definições</h2>

      <h3 style={{ color: '#34495E', marginBottom: '15px', fontSize: '20px' }}>Interpretação</h3>
      <p style={{ color: '#555', fontSize: '16px', lineHeight: '1.7' }}>
        As palavras cuja inicial é maiúscula têm significados definidos nas seguintes condições. As definições seguintes terão o mesmo significado, independentemente de aparecerem no singular ou no plural.
      </p>

      <h3 style={{ color: '#34495E', marginBottom: '15px', fontSize: '20px' }}>Definições</h3>
      <p style={{ color: '#555', fontSize: '16px', lineHeight: '1.7' }}>Para os fins destes Termos e Condições:</p>
      <ul style={{ color: '#555', fontSize: '16px', lineHeight: '1.7', paddingLeft: '20px' }}>
        <li><strong>Afiliada</strong> significa uma entidade que controla, é controlada ou está sob controle comum com uma parte, onde "controle" significa posse de 50% ou mais das ações, participações ou outros títulos com direito a voto para a eleição de diretores ou outra autoridade de gestão.</li>
        <li><strong>Conta</strong> significa uma conta única criada para acessar nosso Serviço.</li>
        <li><strong>Dispositivo</strong> significa qualquer dispositivo que pode acessar o Serviço, como um computador, telefone celular ou tablet digital.</li>
        <li><strong>Serviço</strong> refere-se ao site investai, acessível a partir de <a href="https://investai.netlify.app/" style={{ color: '#2E86C1', textDecoration: 'none' }}>https://investai.netlify.app/</a>.</li>
        <li><strong>Termos e Condições</strong> (também referidos como "Termos") significam estes Termos e Condições que formam o contrato completo entre Você e a Empresa.</li>
        <li><strong>Você</strong> significa o indivíduo acessando ou usando o Serviço, ou a empresa, ou outra entidade legal em nome da qual tal indivíduo está acessando ou usando o Serviço, conforme aplicável.</li>
      </ul>

      <h2 style={{ color: '#2E86C1', marginTop: '40px', marginBottom: '20px', fontSize: '24px' }}>Reconhecimento</h2>
      <p style={{ color: '#555', fontSize: '16px', lineHeight: '1.7' }}>
        Estes são os Termos e Condições que regem o uso deste Serviço e o acordo que opera entre Você e a Empresa. Estes Termos e Condições estabelecem os direitos e obrigações de todos os usuários em relação ao uso do Serviço.
      </p>
      <p style={{ color: '#555', fontSize: '16px', lineHeight: '1.7' }}>
        Seu acesso e uso do Serviço está condicionado à sua aceitação e conformidade com estes Termos e Condições. Estes Termos e Condições se aplicam a todos os visitantes, usuários e outras pessoas que acessam ou usam o Serviço.
      </p>
      <p style={{ color: '#555', fontSize: '16px', lineHeight: '1.7' }}>
        Ao acessar ou usar o Serviço, Você concorda em cumprir estes Termos e Condições. Se Você discordar de qualquer parte destes Termos e Condições, então Você não poderá acessar o Serviço.
      </p>

      <h2 style={{ color: '#2E86C1', marginTop: '40px', marginBottom: '20px', fontSize: '24px' }}>Direitos de Propriedade Intelectual</h2>
      <p style={{ color: '#555', fontSize: '16px', lineHeight: '1.7' }}>
        O Serviço e seu conteúdo original (excluindo o Conteúdo fornecido por Você ou outros usuários), recursos e funcionalidades são e continuarão sendo de propriedade exclusiva da Empresa e de seus licenciadores.
      </p>
      <p style={{ color: '#555', fontSize: '16px', lineHeight: '1.7' }}>
        Você não deve usar o Serviço para copiar, distribuir, modificar, ou utilizar indevidamente qualquer parte dele sem o consentimento por escrito da Empresa.
      </p>

      <h2 style={{ color: '#2E86C1', marginTop: '40px', marginBottom: '20px', fontSize: '24px' }}>Limitações de Responsabilidade</h2>
      <p style={{ color: '#555', fontSize: '16px', lineHeight: '1.7' }}>
        Na máxima extensão permitida pela lei aplicável, em nenhum caso a Empresa ou seus fornecedores serão responsáveis por quaisquer danos especiais, incidentais, indiretos ou consequenciais, sejam eles relacionados ao uso ou à incapacidade de uso do Serviço.
      </p>

      <h2 style={{ color: '#2E86C1', marginTop: '40px', marginBottom: '20px', fontSize: '24px' }}>Rescisão</h2>
      <p style={{ color: '#555', fontSize: '16px', lineHeight: '1.7' }}>
        Podemos encerrar ou suspender Seu acesso imediatamente, sem aviso prévio ou responsabilidade, por qualquer motivo, incluindo se Você violar estes Termos e Condições.
      </p>

      <h2 style={{ color: '#2E86C1', marginTop: '40px', marginBottom: '20px', fontSize: '24px' }}>Lei Aplicável</h2>
      <p style={{ color: '#555', fontSize: '16px', lineHeight: '1.7' }}>
        As leis do Brasil, excluindo suas regras de conflitos de leis, regerão estes Termos e o uso do Serviço.
      </p>

      <h2 style={{ color: '#2E86C1', marginTop: '40px', marginBottom: '20px', fontSize: '24px' }}>Alterações Nestes Termos</h2>
      <p style={{ color: '#555', fontSize: '16px', lineHeight: '1.7' }}>
        Reservamo-nos o direito de modificar ou substituir estes Termos a qualquer momento. Se a alteração for material, tentaremos fornecer um aviso com pelo menos 30 dias de antecedência antes que quaisquer novos termos entrem em vigor.
      </p>

      <h2 style={{ color: '#2E86C1', marginTop: '40px', marginBottom: '20px', fontSize: '24px' }}>Entre em Contato</h2>
      <p style={{ color: '#555', fontSize: '16px', lineHeight: '1.7' }}>
        Se você tiver dúvidas sobre estes Termos e Condições, entre em contato conosco:
      </p>
      <ul style={{ color: '#555', fontSize: '16px', lineHeight: '1.7', paddingLeft: '20px' }}>
        <li>Email: snaxofc11@gmail.com</li>
      </ul>
    </div>
  );
};

export default TermosServicos;
