import React, { useState } from 'react';
import { AppBar as MuiAppBar, Toolbar, Typography, IconButton, Grid, Box, Drawer, List, ListItem,  ListItemText, Divider } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';


const drawerWidth = 240;

// Função para remover caracteres especiais e converter o texto em minúsculas
const sanitizeText = (text: string): string => {
  return text
    .toLowerCase()
    .normalize('NFD') // Normaliza caracteres especiais como acentos
    .replace(/[\u0300-\u036f]/g, '') // Remove acentos
    .replace(/\s+/g, '-') // Substitui espaços por hífens
    .replace(/[^\w-]/g, ''); // Remove caracteres especiais (mantém letras, números e hífens)
};

const Header = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery('(max-width:900px)');
  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const drawer = (
    <Box
      sx={{
        width: drawerWidth,
        bgcolor: theme.palette.background.default,
        paddingTop: 2,
      }}
      role="presentation"
      onClick={handleDrawerClose}
      onKeyDown={handleDrawerClose}
    >
      <List sx={{ padding: 0 }}>
        {['Home', 'dividedos fiis e acoes','Educação Financeira','fiis', 'ações','Guias de Investimento', 'Estudos de Caso'].map((text, index) => (
          <ListItem
            key={text}
            component={Link}
            to={`/${sanitizeText(text)}`}
            sx={{
              padding: '10px 20px',
              borderRadius: 1,
              margin: '5px 10px',
              transition: 'background-color 0.2s ease-in-out',
              '&:hover': {
                backgroundColor: theme.palette.primary.main,
                color: '#fff',
              },
              '&.Mui-selected': {
                backgroundColor: theme.palette.primary.light,
                color: theme.palette.primary.contrastText,
              },
            }}
            disablePadding
          >
            <ListItemText
              primary={text}
              primaryTypographyProps={{
                sx: { fontWeight: 500, color: theme.palette.text.primary },
              }}
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );
  

  return (
    <>
      <MuiAppBar
        position="fixed"
        sx={{
          bgcolor: theme.palette.primary.dark,
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)',
        }}
      >
        <Toolbar sx={{ justifyContent: 'center', position: 'relative' }}>
          <Typography
          variant="h6"
          noWrap
          component={Link} // Altera o componente para Link
          to="/" // Define a rota para o Home
          sx={{
            position: 'absolute',
            left: '50%',
            transform: 'translateX(-50%)',
            fontWeight: 'bold',
            color: '#FFF',
            textDecoration: 'none', // Remove a sublinhado padrão do link
            '&:hover': {
              color: theme.palette.primary.light, // Opcional: muda a cor ao passar o mouse
            },
          }}
        >
          Salve investidor
        </Typography>

          {isSmallScreen && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="end"
              sx={{ position: 'absolute', right: 20 }}
            >
              <MenuIcon />
            </IconButton>
          )}
        </Toolbar>

        {!isSmallScreen && (
          <Grid
            container
            justifyContent="center"
            sx={{
              padding: theme.spacing(1),
              '& a': {
                textDecoration: 'none',
                color: '#FFF',
                fontWeight: 'bold',
                padding: theme.spacing(2),
              },
              '& a:hover': {
                backgroundColor: theme.palette.primary.light,
                borderRadius: '5px',
              },
            }}
          >
            {['Home', 'dividedos fiis e ações','Educação Financeira','fiis', 'ações','Guias de Investimento', 'Estudos de Caso'].map((text, index) => (
              <Typography
                variant="button"
                component={Link}
                to={`/${sanitizeText(text)}`}
                key={index}
              >
                {text}
              </Typography>
            ))}
          </Grid>
        )}
      </MuiAppBar>

      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            bgcolor: theme.palette.background.paper,
          },
        }}
        anchor="left"
        open={open}
        onClose={handleDrawerClose}
      >
        <Box>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </Box>
        <Divider />
        {drawer}
      </Drawer>

      {!isSmallScreen && <Box sx={{ marginBottom: 20 }}></Box>}
      {isSmallScreen && <Box sx={{ marginBottom: 10 }}></Box>}
    </>
  );
};

export default Header;
