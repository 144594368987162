import React from 'react';
import DividendCalculator from '../components/DividendCalculator';

const CalcDividendosAcoes: React.FC = () => {
  return (
    <div style={{
      backgroundImage: 'linear-gradient(to right, #e0eafc, #cfdef3)',
      minHeight: '50vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }}>
      <div>
        <h1 style={{  textAlign: 'center', marginBottom: '40px' }}>Calculadora de Dividendos para Ações e Fiis</h1>
        <DividendCalculator />
      </div>
    </div>
  );
};

export default CalcDividendosAcoes;
