import React from 'react';
import Carousel from '../components/Carousel'; // Importando o componente Carousel
import { Box, Typography } from '@mui/material';
import { guiasdeinvestimentos } from '../data/artigos/investimentos';


const GuiasInvestimento: React.FC = () => {
  return (
    <Box sx={{ padding: '40px 20px', backgroundColor: '#f9f9f9' }}>
      {/* Cabeçalho da Página */}
      <Typography variant="h3" align="center" gutterBottom sx={{ color: '#333', fontWeight: 'bold', marginBottom: '30px' }}>
        Guias de Investimento
      </Typography>

      <Typography variant="h6" align="center" sx={{ color: '#555', marginBottom: '40px' }}>
        Explore artigos completos sobre FIIs, Ações e Renda Fixa para otimizar seus investimentos.
      </Typography>

      {/* Carrossel de Artigos sobre FIIs */}
      <Carousel categoryTitle="Estrategias de investimentos" articles={guiasdeinvestimentos} />

      {/* Seção de Informação sobre Investimentos */}
      <Box sx={{ marginTop: '50px', padding: '40px', backgroundColor: '#fff', borderRadius: '15px', boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)' }}>
        <Typography variant="h4" gutterBottom sx={{ color: '#333', fontWeight: 'bold' }}>
          Por que Diversificar em FIIs, Ações e Renda Fixa?
        </Typography>
        <Typography variant="body1" sx={{ color: '#555', lineHeight: '1.8' }}>
          Diversificar seus investimentos entre Fundos Imobiliários, Ações e Renda Fixa é uma estratégia poderosa para reduzir riscos e aumentar seu retorno. Cada um desses tipos de investimento oferece vantagens específicas que, quando combinadas, ajudam a balancear sua carteira de investimentos.
        </Typography>

        <Typography variant="h5" gutterBottom sx={{ marginTop: '20px', color: '#333', fontWeight: 'bold' }}>
          Fundos Imobiliários (FIIs)
        </Typography>
        <Typography variant="body1" sx={{ color: '#555', lineHeight: '1.8' }}>
          Os FIIs são uma excelente maneira de ganhar renda passiva através de investimentos imobiliários sem a necessidade de comprar propriedades diretamente. Além disso, são isentos de imposto de renda sobre os dividendos para investidores individuais.
        </Typography>

        <Typography variant="h5" gutterBottom sx={{ marginTop: '20px', color: '#333', fontWeight: 'bold' }}>
          Ações
        </Typography>
        <Typography variant="body1" sx={{ color: '#555', lineHeight: '1.8' }}>
          Investir em ações permite que você se torne sócio das maiores empresas do país. Através das ações, você participa do crescimento dessas empresas e pode obter lucros através da valorização e dos dividendos.
        </Typography>

        <Typography variant="h5" gutterBottom sx={{ marginTop: '20px', color: '#333', fontWeight: 'bold' }}>
          Renda Fixa
        </Typography>
        <Typography variant="body1" sx={{ color: '#555', lineHeight: '1.8' }}>
          A renda fixa oferece segurança e previsibilidade. Ideal para investidores conservadores, os títulos de renda fixa garantem um retorno estável e são fundamentais para quem busca proteger o patrimônio com menor risco.
        </Typography>
      </Box>
    </Box>
  );
};

export default GuiasInvestimento;
