import React from 'react';

const NotFound: React.FC = () => {
  return (
    <div style={{ textAlign: 'center', padding: '40px' }}>
      <h1 style={{ fontSize: '3rem', color: '#E74C3C' }}>404</h1>
      <p style={{ fontSize: '1.5rem', color: '#555' }}>Página não encontrada</p>
      <a href="/" style={{ color: '#3498DB', textDecoration: 'none', fontSize: '1.2rem' }}>
        Voltar para a página inicial
      </a>
    </div>
  );
};

export default NotFound;
