import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from '../pages/Home';
import FIIs from '../pages/FIIs';
import Acoes from '../pages/Acoes';
import CalcDividendosAcoes from '../pages/CalcDividendosAcoes';
import Sobre from '../pages/Sobre';
import PoliticasPrivacidade from '../pages/PoliticasPrivacidade';
import TermosServicos from '../pages/TermosServicos';
import Contato from '../pages/contato';
import PoliticaCookies from '../pages/PoliticaCookies';
import NotFound from '../pages/NotFound';
import GuiasInvestimento from '../pages/GuiasInvestimento';
import CaseStudies from '../pages/EstudoCasos';
import EducacaoFinanceira from '../pages/EducacaoFinanceira';
import ArticlePrinter from '../components/ArticlePrinter';

const AppRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/Home" element={<Home />} />
      <Route path="/dividedos-fiis-e-acoes" element={<CalcDividendosAcoes />} />
      <Route path="/educacao-financeira" element={<EducacaoFinanceira />} />
      <Route path="/fiis" element={<FIIs />} />
      <Route path="/acoes" element={<Acoes />} />
      <Route path="/guias-de-investimento" element={<GuiasInvestimento />} />
      <Route path="/estudos-de-caso" element={<CaseStudies />} />

      {/* Aqui está a atualização para 'artigos'
      <Route path="/artigos/acoes/:id" element={<ArticlePrinter />} />
      <Route path="/artigos/fiis/:id" element={<ArticlePrinter />} /> */}
      <Route path="/artigos/:categoria/:id" element={<ArticlePrinter />} />
      {/* <Route path="/artigos/investimentos/:id" element={<ArticlePrinter />} /> */}
      {/* <Route path="/artigos/estudocasos/:id" element={<ArticlePrinter />} />
      <Route path="/artigos/educacaoFinaceira/:id" element={<ArticlePrinter />} />
       */}
      <Route path="/sobre" element={<Sobre />} />
      <Route path="/termos-de-servico" element={<TermosServicos />} />
      <Route path="/contato" element={<Contato />} />
      <Route path="/politica-de-cookies" element={<PoliticaCookies />} />
      <Route path="/politica-de-privacidade" element={<PoliticasPrivacidade />} />

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default AppRoutes;
