import React, { Suspense } from 'react';
import { Card, CardContent, Typography, Button, Box } from '@mui/material';
import { Link } from 'react-router-dom';
// import { casosArtigos } from '../data/artigos/casos';
import { guiasdeinvestimentos } from '../data/artigos/investimentos';
// Lazy load para o componente Carousel
const Carousel = React.lazy(() => import('../components/Carousel'));

// Importando os dados diretamente, sem lazy loading


const Home: React.FC = () => {
  return (
    <div>
      {/* Card de "Calcular Dividendo" */}
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '40px 0' }}>
        <Card sx={{ maxWidth: '600px', textAlign: 'center', padding: '20px', borderRadius: '10px', boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)' }}>
          <CardContent>
            <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#1976d2', marginBottom: '20px' }}>
              Calcular dividendo de ações ou FIIs agora
            </Typography>
            <Typography variant="body1" color="text.secondary" sx={{ marginBottom: '20px' }}>
              Utilize nossa calculadora para descobrir o quanto você pode ganhar em dividendos ao investir em ações ou fundos imobiliários.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              size="large"
              component={Link}
              to="/dividedos-fiis-e-acoes"
              sx={{ backgroundColor: '#1976d2', color: '#fff', '&:hover': { backgroundColor: '#1565c0' } }}
            >
              Calcular Agora
            </Button>
          </CardContent>
        </Card>
      </Box>

      {/* Carrosséis com Suspense */}
      <Suspense fallback={<div>Carregando...</div>}>
        <Carousel categoryTitle="Estrategias de investimentos" articles={guiasdeinvestimentos} />
        {/* <Carousel categoryTitle="Estudos de Caso" articles={casosArtigos} /> */}
      </Suspense>
    </div>
  );
};

export default Home;
