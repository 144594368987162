import React from 'react';
import Slider from 'react-slick';
import { Card, CardContent, Typography, Button, CardMedia, CardActions, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

interface CarouselProps {
  categoryTitle: string;
  articles: {
    id: string;
    title: string;
    summary: string;
    image: string;
    coverImage: string;
    date: string;
    comments: number;
    type: string;
  }[];
}

const Carousel: React.FC<CarouselProps> = ({ categoryTitle, articles }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3, // Mostra 3 cards para telas grandes
    slidesToScroll: 1, // Avança um slide por vez
    autoplay: true,
    autoplaySpeed: 3000,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1, // Avança um slide por vez em telas médias
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1, // Avança um slide por vez em telas pequenas
        },
      },
    ],
  };

  return (
    <Box
      sx={{
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '20px 0', // Reduzido de 40px para 20px
        backgroundColor: '#f5f5f5',
        width: '100%',
      }}
    >
      <Box sx={{ maxWidth: '1200px', width: '90%', margin: '0 auto' }}> {/* Ajuste de maxWidth para centralizar melhor */}
        <Typography
          variant="h4"
          gutterBottom
          align="center"
          sx={{ color: '#333', marginBottom: 4, fontWeight: 'bold' }}
        >
          {categoryTitle}
        </Typography>
        <Slider {...settings}>
          {articles.map((article) => (
            <Box
              key={article.id}
              sx={{ padding: '0 10px', display: 'flex', justifyContent: 'center' }} // Ajustado o padding para 10px
            >
              <Card
              sx={{
                width: '350px', // Define uma largura fixa
                maxWidth: '480px', // Limita a largura máxima para garantir um layout consistente
                height: '520px', // Define uma altura fixa
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
                borderRadius: '15px',
                transition: 'transform 0.3s ease',
                '&:hover': { transform: 'scale(1.05)' },
              }}
            >

              
                <CardMedia
                  component="img"
                  height="200"
                  image={article.coverImage}
                  alt={`Imagem relacionada ao artigo: ${article.title}`}
                  sx={{ objectFit: 'cover', borderTopLeftRadius: '15px', borderTopRightRadius: '15px' }}
                  loading="lazy"
                />

                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography gutterBottom variant="h6" component="div" sx={{ color: '#1976d2', fontWeight: '600' }}>
                    {article.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary" sx={{ marginBottom: 2 }}>
                    {article.date} · {article.comments === 0 ? 'Nenhum Comentário' : `${article.comments} Comentários`}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {article.summary}
                  </Typography>
                </CardContent>
                <CardActions sx={{ justifyContent: 'center', paddingBottom: '16px' }}>
                  <Button
                    aria-label={`Ler mais sobre ${article.title}`}
                    component={Link}
                    to={`/artigos/${article.type.toLowerCase()}/${article.id}`}
                    size="small"
                    variant="contained"
                    sx={{
                      backgroundColor: '#1976d2',
                      color: '#fff',
                      '&:hover': {
                        backgroundColor: '#1565c0',
                      },
                    }}
                  >
                    Ler Mais
                  </Button>
                </CardActions>
              </Card>
            </Box>
          ))}
        </Slider>
      </Box>
    </Box>
  );
};

// Componentes para customizar as setas de navegação
const SampleNextArrow = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <button
      className={className}
      style={{
        ...style,
        background: '#1976d2',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '40px',
        height: '40px',
        color: 'white',
        zIndex: 1,
      }}
      onClick={onClick}
    />
  );
};

const SamplePrevArrow = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <button
      className={className}
      style={{
        ...style,
        background: '#1976d2',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '40px',
        height: '40px',
        color: 'white',
        zIndex: 1,
      }}
      onClick={onClick}
    />
  );
};

export default Carousel;
