import React from 'react';
import { Box, Typography, Card, CardContent, Grid } from '@mui/material';

const Sobre: React.FC = () => {
  return (
    <Box sx={{ padding: '40px 20px', backgroundColor: '#f9f9f9' }}>
      <Typography variant="h2" align="center" gutterBottom sx={{ fontWeight: 'bold', color: '#1976d2' }}>
        Sobre Nós
      </Typography>

      <Typography variant="body1" align="center" sx={{ maxWidth: '800px', margin: '0 auto', color: '#555' }}>
        No <strong>InvestAI</strong>, nosso objetivo é fornecer informações claras e precisas sobre investimentos para ajudá-lo a tomar decisões financeiras informadas.
        Nossa plataforma foi criada com o compromisso de educar, informar e facilitar o acesso a estratégias de investimento de forma acessível e personalizada.
      </Typography>

      <Grid container spacing={4} sx={{ marginTop: '40px' }}>
        <Grid item xs={12} md={4}>
          <Card sx={{ boxShadow: 3, borderRadius: 2 }}>
            <CardContent>
              <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#1976d2' }}>Nossa Missão</Typography>
              <Typography variant="body2" color="textSecondary" sx={{ marginTop: 2 }}>
                Democratizar o acesso a informações financeiras e simplificar o processo de investimento, capacitando investidores de todos os níveis.
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={4}>
          <Card sx={{ boxShadow: 3, borderRadius: 2 }}>
            <CardContent>
              <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#1976d2' }}>Por que Escolher o InvestAI?</Typography>
              <Typography variant="body2" color="textSecondary" sx={{ marginTop: 2 }}>
                Nossas ferramentas de análise financeira usam tecnologias modernas para fornecer insights personalizados, baseados em dados, permitindo que você maximize seus investimentos com confiança.
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={4}>
          <Card sx={{ boxShadow: 3, borderRadius: 2 }}>
            <CardContent>
              <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#1976d2' }}>Nossa Equipe</Typography>
              <Typography variant="body2" color="textSecondary" sx={{ marginTop: 2 }}>
                Somos uma equipe dedicada de especialistas financeiros e desenvolvedores de software, apaixonados por tornar o mundo dos investimentos acessível a todos.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Typography variant="h4" align="center" sx={{ marginTop: '60px', color: '#1976d2' }}>
        Compromisso com a Transparência
      </Typography>
      <Typography variant="body1" align="center" sx={{ maxWidth: '800px', margin: '20px auto', color: '#555' }}>
        No <strong>InvestAI</strong>, priorizamos a transparência e a segurança dos seus dados. Seguimos práticas rigorosas de proteção de dados para garantir que suas informações pessoais e financeiras estejam seguras. Todos os nossos serviços são baseados em confiança e confiabilidade, alinhados com as diretrizes de melhores práticas da indústria financeira.
      </Typography>
    </Box>
  );
};

export default Sobre;
