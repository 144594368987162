import React from 'react';
import { Box, Typography } from '@mui/material';


const CaseStudies: React.FC = () => {
  return (
    <Box sx={{ padding: '40px 20px', backgroundColor: '#f9f9f9' }}>
      {/* Cabeçalho da Página */}
      <Typography variant="h3" align="center" gutterBottom sx={{ color: '#333', fontWeight: 'bold', marginBottom: '30px' }}>
        Estudos de Caso
      </Typography>

      <Typography variant="h6" align="center" sx={{ color: '#555', marginBottom: '40px' }}>
        Explore exemplos reais de sucesso e estudos de caso para entender como outros investidores conquistaram grandes resultados no mercado financeiro.
      </Typography>

      {/* Carrossel de Estudos de Caso */}
      {/* <Carousel categoryTitle="Casos de Sucesso em Investimentos" articles={estudodecasos} /> */}

      {/* Carrossel de Histórias de Sucesso */}
      {/* <Carousel categoryTitle="Histórias Inspiradoras de Investidores" articles={acoesArtigos} /> */}

      {/* Seção Informativa sobre Estudos de Caso */}
      <Box sx={{ marginTop: '50px', padding: '40px', backgroundColor: '#fff', borderRadius: '15px', boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)' }}>
        <Typography variant="h4" gutterBottom sx={{ color: '#333', fontWeight: 'bold' }}>
          O Valor dos Estudos de Caso no Mundo dos Investimentos
        </Typography>
        <Typography variant="body1" sx={{ color: '#555', lineHeight: '1.8' }}>
          Estudos de caso proporcionam uma análise detalhada de como estratégias de investimento bem-sucedidas foram aplicadas em situações do mundo real. Ao aprender com as experiências de outros investidores, você pode aprimorar suas próprias estratégias e evitar armadilhas comuns.
        </Typography>

        <Typography variant="h5" gutterBottom sx={{ marginTop: '20px', color: '#333', fontWeight: 'bold' }}>
          Exemplos Reais e Aplicações Práticas
        </Typography>
        <Typography variant="body1" sx={{ color: '#555', lineHeight: '1.8' }}>
          Desde investimentos em ações de tecnologia até a construção de carteiras diversificadas com FIIs, os estudos de caso oferecem insights valiosos para todos os tipos de investidores. Acompanhe como investidores bem-sucedidos ajustaram suas táticas em mercados voláteis, aproveitaram oportunidades e maximizaram retornos.
        </Typography>

        <Typography variant="h5" gutterBottom sx={{ marginTop: '20px', color: '#333', fontWeight: 'bold' }}>
          Estratégias Baseadas em Evidências
        </Typography>
        <Typography variant="body1" sx={{ color: '#555', lineHeight: '1.8' }}>
          Em vez de depender de teorias, os estudos de caso baseiam-se em resultados concretos. As estratégias aplicadas por investidores bem-sucedidos podem servir de guia para suas próprias decisões de investimento, adaptando-as às suas necessidades e tolerância ao risco.
        </Typography>
      </Box>
    </Box>
  );
};

export default CaseStudies;
