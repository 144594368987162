import React, { useState, useEffect } from 'react';
import { Box, Button, Typography } from '@mui/material';

const CookieConsent = () => {
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    // Verifica se o usuário já aceitou os cookies
    const consent = localStorage.getItem('cookieConsent');
    if (!consent) {
      setShowBanner(true); // Mostra o banner se não houver consentimento
    }
  }, []);

  const handleAccept = () => {
    // Armazena a decisão de consentimento e esconde o banner
    localStorage.setItem('cookieConsent', 'accepted');
    setShowBanner(false);
  };

  if (!showBanner) {
    return null; // Não exibe o banner se o usuário já aceitou
  }

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        backgroundColor: '#1976d2',
        color: '#fff',
        padding: '16px',
        textAlign: 'center',
        zIndex: 1000, // Mantém o banner sobre outros elementos
      }}
    >
      <Typography variant="body1" sx={{ display: 'inline', marginRight: '16px' }}>
        Nós usamos cookies para melhorar sua experiência em nosso site. Ao continuar navegando, você concorda com nossa{' '}
        <a href="/politica-de-cookies" style={{ color: '#fff', textDecoration: 'underline' }}>
          Política de Cookies.
        </a>
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={handleAccept}
        sx={{
          backgroundColor: '#fff',
          color: '#1976d2',
          '&:hover': {
            backgroundColor: '#f5f5f5',
          },
        }}
      >
        Recusar
      </Button>
      <Box sx={{ display: 'inline', margin: '0 16px' }} />
      <Button
        variant="contained"
        color='success'
        onClick={handleAccept}
      >
        Aceitar
      </Button>
    </Box>
  );
};

export default CookieConsent;
