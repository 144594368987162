import React from 'react';
import { Box, Grid, Typography, Link, Container } from '@mui/material';

const Footer = () => {
  return (
    <Box sx={{ backgroundColor: '#1976d2', padding: '40px 0', color: '#fff', mt: 4 }}>
      <Container>
        <Grid container spacing={3} justifyContent="space-between">
          {/* Sobre */}
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6" gutterBottom>
              Sobre
            </Typography>
            <Typography variant="body2">
              Nós somos um portal de tecnologia e investimentos, nosso trabalho é trazer conteúdo de qualidade para você.
            </Typography>
            <Link href="/sobre" sx={{ color: '#fff', display: 'block', marginTop: '10px' }}>Saiba mais</Link>
          </Grid>

          {/* Políticas */}
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6" gutterBottom>
              Políticas
            </Typography>
            <Link href="/politica-de-privacidade" sx={{ color: '#fff', display: 'block' }}>Política de Privacidade</Link>
            <Link href="/termos-de-servico" sx={{ color: '#fff', display: 'block', marginTop: '10px' }}>Termos de Serviço</Link>
            <Link href="/politica-de-cookies" sx={{ color: '#fff', display: 'block', marginTop: '10px' }}>Política de Cookies</Link>
          </Grid>

          {/* Contato */}
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6" gutterBottom>
              Contato
            </Typography>
            <Typography variant="body2">
              Entre em contato conosco para sugestões, parcerias ou suporte.
            </Typography>
            <Link href="/contato" sx={{ color: '#fff', display: 'block', marginTop: '10px' }}>Fale Conosco</Link>
          </Grid>

          {/* Redes Sociais */}
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6" gutterBottom>
              Siga-nos
            </Typography>
            <Link href="https://www.youtube.com/channel/UCvaWallqKyvGO3xir2E6JtQ" target="_blank" sx={{ color: '#fff', display: 'block', marginTop: '10px' }}>Youtube</Link>
            <Link href="https://www.facebook.com" target="_blank" sx={{ color: '#fff', display: 'block', marginTop: '10px' }}>Facebook</Link>
            <Link href="https://www.instagram.com" target="_blank" sx={{ color: '#fff', display: 'block', marginTop: '10px' }}>Instagram</Link>
            
          </Grid>
        </Grid>

        <Box sx={{ borderTop: '1px solid #fff', mt: 4, pt: 3, textAlign: 'center' }}>
          <Typography variant="body2">
            © {new Date().getFullYear()} Salve investidor. Todos os direitos reservados.
          </Typography>
          <Typography variant="body2" sx={{ marginTop: '10px' }}>
            Este site utiliza cookies para melhorar sua experiência. Ao continuar, você concorda com nossa{' '}
            <Link href="/politica-de-cookies" sx={{ color: '#fff', textDecoration: 'underline' }}>
              Política de Cookies.
            </Link>
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
